import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { CONFIGURATION_TYPES } from 'state-domains/constants';
import {
    buildConfigurationHeadersUrl,
    buildEditSampleDispatchHeadersUrl,
} from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';
import { ConfigurationHeadersResponse } from '../types';

export const deleteConfigurationHeader = (
    id: string,
    configType: CONFIGURATION_TYPES,
): Observable<ConfigurationHeadersResponse> => {
    const url =
        configType === CONFIGURATION_TYPES.HEADERS
            ? buildConfigurationHeadersUrl(id)
            : buildEditSampleDispatchHeadersUrl(id);
    const obs = sendDeleteRequestWithXSRFToken(url);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationHeadersResponse>) => {
            const res = convertToCamel<ConfigurationHeadersResponse>(response);
            return observableOf(res);
        }),
    );
};
